import { LayoutType } from '../components/layout/layout';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { wrapper } from '../store';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { i18n } from '../../next-i18next.config';
import Error from 'next/error';
import { configuration } from '../configurations/configuration';

const Custom404 = (): JSX.Element => {
  return <Error statusCode={404} />;
};

Custom404.layout = LayoutType.HOME;

export default Custom404;

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  () => async ({ locale }: GetStaticPropsContext) => {
    return {
      props: {
        ...(await serverSideTranslations(locale ?? i18n.defaultLocale, ['common', 'components:layout']))
      },
      revalidate: configuration.revalidation.timeSec
    };
  }
);
